.first-stop {
  stop-color: #02617d;
  stop-opacity: 0;
  animation-name: fill-animation;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.last-stop {
  stop-color: #02617d;
  stop-opacity: 0;
  animation-name: fill-animation;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes fill-animation {
  0% {
    stop-opacity: 0;
  }

  100% {
    stop-opacity: 1;
  }
}
