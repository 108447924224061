@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100&display=swap);
.first-stop {
  stop-color: #02617d;
  stop-opacity: 0;
  -webkit-animation-name: fill-animation;
          animation-name: fill-animation;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.last-stop {
  stop-color: #02617d;
  stop-opacity: 0;
  -webkit-animation-name: fill-animation;
          animation-name: fill-animation;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes fill-animation {
  0% {
    stop-opacity: 0;
  }

  100% {
    stop-opacity: 1;
  }
}

@keyframes fill-animation {
  0% {
    stop-opacity: 0;
  }

  100% {
    stop-opacity: 1;
  }
}

/* * {
  font-family: "Roboto Mono", monospace;
} */

body {
  margin: 0;
  padding: 0;
  background-color: #d3d3d3;
}

.menuBtn {
  position: relative;
  width: 5vh;
  height: 4vh;
  padding: 4px 8px;
  background-color: transparent;
  cursor: pointer;
  z-index: 10;
}

.menuBtn span {
  width: 100%;
  height: 4px;
  display: block;
  background: #006ba6;
  margin: 6px 0;
  transition: 0.5s;
}

.menuBtn.active span {
  position: absolute;
  width: calc(100% - 2.4vh);
  top: calc(50% - 0.6vh);
}

.menuBtn.active span:nth-child(1) {
  transform: rotate(45deg);
}

.menuBtn.active span:nth-child(3) {
  transform: rotate(-45deg);
}

.menuBtn.active span:nth-child(2) {
  display: none;
}

.menuBtn:hover span {
  background: #006ba6;
}

a {
  text-decoration: none;
  color: white;
}

.chevron {
  transform: rotate(0deg);
  transition: 0.5s ease;
}

.chevronRotate {
  transform: rotate(180deg);
  transition: 0.5s ease;
}

